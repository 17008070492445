import React  from 'react';

// react
import {Disclosure} from "@headlessui/react";

// Images
import TempPng from "../images/resources/how-it-works/lady-typing.png";

// SVG
import Backdrop from "../images/resources/how-it-works/backdrop.inline.svg";

// Heroicons
import {ChevronDownIcon} from "@heroicons/react/outline";

// Shared components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";

export default function FAQ ({ location, data }) {

    const faqs = [
        {
            question: "How do I know if my central station is compatible with Tech Tester?",
            answer:
                `Please take a look at our list of currently compatible central stations at alarmtechtester.com/compatible-central-stations.`,
        },
        {
            question: "What do I need to setup an account with Tech Tester?",
            answer:
                "All you need to get started with Tech Tester is a valid Central Station log-in with the appropriate permissions allowed. After you link your credentials to Tech Tester, you are all ready to get started!",
        },
        {
            question: "I use multiple central stations. Does Tech Tester allow that?",
            answer:
                "Absolutely. Tech Tester will allow you to link as many central stations to your account as you need.",
        },
        {
            question: "My free trial has expired, how do I continue to use Tech Tester?",
            answer:
                "To continue using Tech Tester, please enter your payment details within your account screen. If you need help with payments, please feel free to reach out to our support team, who will gladly assist in getting you back up and running with Tech Tester.",
        },
        {
            question: "When will my credit card be billed for Tech Tester?",
            answer:
                "Your credit card will be billed on the 1st of each month. If you joined late in the month, your credit card charge will reflect that. You will pay a pro-rated amount, only for the time you were using Tech Tester.",
        },
        {
            question: "I would like one of my technicians to have full access. Is that possible?",
            answer:
                "Absolutely. With Tech Tester, you are in full control over how much (or how little) access your technicians have. You can change the access levels at any time.",
        },
    ]


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div>
            <Header/>
            {/* Hero section */}
            <div className="relative bg-myGray-300 py-24">
                <div className={"relative z-10 pt-10 px-10 mx-auto max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-10"}>
                    <div className={"my-auto text-left "}>
                        <h1 className={"md:text-5xl text-4xl text-myGray-600 font-bold"}>Frequently Asked Questions</h1>
                        <h3 className={"text-myGray-600 text-lg pt-10"}>Have more questions about Tech Tester? <br/>We are here to help - every step of the way.</h3>

                    </div>
                    <div className={"m-auto relative"}>
                        <img
                            className="z-10 relative"
                            src={TempPng}
                            alt="Temp Item"
                        />
                    </div>
                </div>
                <Backdrop
                    className={'fill-myGray-250 object-scale-down absolute z-2 top-36 md:top-0 right-10 md:right-52 lg:right-96 h-80 w-auto'}/>
            </div>


            {/* Alternating Feature Sections */}
            <div className="relative overflow-hidden relative">
                <div aria-hidden="true" className="absolute z-2 inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"/>

                {/* FAQ Section */}
                <div className="bg-white">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                        <div className="max-w-3xl mx-auto">
                            <h2 className="text-center text-3xl font-extrabold text-gray-900">FAQ</h2>
                            {faqs.map((faq) => (
                                <Disclosure as="div" key={faq.question}
                                            className="pt-6 bg-myGray-300 rounded-md m-5 p-5">
                                    {({open}) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button
                                                    className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="font-medium text-gray-900">{faq.question}</span>
                                                    <span className="ml-6 h-7 flex items-center">
                                                      <ChevronDownIcon
                                                          className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                          aria-hidden="true"
                                                      />
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-base text-gray-500">{faq.answer}</p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}